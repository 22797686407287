import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import MintView from "../views/MintView.vue";
import MintView2 from "../views/MintView2.vue";
import MyBagView from "../views/MyBagView.vue";
import RelayOrdersView from "../views/RelayOrdersView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/mint",
    name: "mint",
    component: MintView,
  },
  {
    path: "/mint2",
    name: "mint2",
    component: MintView2,
  },
  {
    path: "/my-bag",
    name: "MyBag",
    component: MyBagView,
  },
  {
    path: "/relay-orders",
    name: "Orders",
    component: RelayOrdersView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
