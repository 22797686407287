const config = {
  apiKey: "AIzaSyC57ZNV-kckr_w6vHruOGVarNr_bRG-7iQ",
  authDomain: "fronksmint.firebaseapp.com",
  projectId: "fronksmint",
  storageBucket: "fronksmint.appspot.com",
  messagingSenderId: "776879621221",
  appId: "1:776879621221:web:427438f28c72df342a728c",
  measurementId: "G-3MZ5Y2CGSZ",
};
export { config };
