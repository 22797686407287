<template>
  <menu-component></menu-component>
  <div
    class="min-h-screen h-full bg-gradient-to-b from-gray-600 to-black w-full text-white flex-col items-center overflow-y-scroll"
  >
    <div
      class="h-56 bg-cover bg-center"
      style="
        background-image: url('https://slavettes-layers.s3.amazonaws.com/fronks-images/banner-image.png');
      "
    ></div>
    <div class="text-white text-center pt-4">
      <div class="w-auto">
        <img
          class="mx-w-sm xl:max-w-xl h-48 mx-auto"
          src="https://slavettes-layers.s3.amazonaws.com/fronks-images/fronks-logotype.png"
        />
        <div
          class="text-4xl md:text-7xl font-extrabold bg-gradient-to-b from-green-200 via-green-400 to-green-700 bg-clip-text text-transparent"
        >
          MINT OG FROGES
        </div>
      </div>
    </div>
    <div class="pt-10 flex items-center items-justify-center">
      <div class="min-w-sm max-w-sm mx-auto h-auto w-96 bg-gray-900 rounded-xl">
        <div class="h-full">
          <div class="pt-6">
            <img
              class="h-48 w-48 mx-auto rounded-xl"
              src="https://slavettes-layers.s3.amazonaws.com/fronks-images/fronks-flash.gif"
            />
          </div>
          <div v-if="orderCount < 999" class="mx-auto pt-5 mt-5">
            <button :class="mintButtonClasses" class="rounded-xl px-6 py-2 m-2">
              <div v-if="new Date() > utcDate">MINT CLOSED</div>
              <div v-else class="text-2xl font-medium">
                <CountdownTimer :date="end" @onFinish="finish()">
                </CountdownTimer>
              </div>
            </button>

            <div class="items-justify-center">
              {{ orderCount }} / 999 minted
            </div>
            <div class="items-justify-center">
              <div ref="payButton" class="buy-extra mx-32"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLogin">
      <button
        @click="logout"
        class="px-4 p-2 m-2 mt-16 bg-red-600 rounded-xl text-white"
      >
        LOGOUT
      </button>
    </div>
    <footer-component></footer-component>>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="mint">
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="relative inline-block align-bottom bg-gray-800 text-gray-200 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
            >
              <div>
                <div
                  class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-pink-100"
                >
                  <i class="fa fa-check text-pink-600"> </i>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    class="text-lg leading-6 font-medium bg-gradient-to-b from-yellow-200 via-yellow-400 to-yellow-700 bg-clip-text text-transparent"
                  >
                    CONGRATS YGMI</DialogTitle
                  >
                  <div class="mt-2">
                    <p class="text-sm text-gray-300">
                      Your FRONK will arrive in your relay wallet in a few
                      minutes.
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gradient-to-r form bg-indigo-600 to-bg-pink-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  @click="closeModal"
                >
                  MINT MORE
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { useWallet } from "./../composition/useWallet.js";
import MenuComponent from "./../components/MenuComponent.vue";
import CountdownTimer from "./../components/CountdownTimer.vue";
import FooterComponent from "./../components/FooterComponent.vue";
import { useCounters, useOrders } from "../services/firebase.js";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    MenuComponent,
    CountdownTimer,
    FooterComponent,
  },
  async setup() {
    const { isLogin } = useWallet();
    let utcDate = new Date(Date.UTC(2022, 3, 15, 21, 0, 0, 0));
    const { allCounters, increment } = useCounters();
    const state = reactive({
      open: false,
      paying: false,
      end: utcDate,
      showTimer: true,
    });

    return {
      ...toRefs(state),
      isLogin,
      allCounters,
      increment,
      utcDate,
    };
  },
  methods: {
    finish() {
      this.showTimer = false;
    },
    async showRelayButton() {
      if (new Date() < this.utcDate) {
        return;
      }
      if (!this.isLoggedIn) {
        return;
      }
      let { sendOrder } = useOrders();
      let { increment } = useCounters();
      let confirmPaid = (e) => {
        increment();
        sendOrder(
          e.txid,
          this.$store.state.relayx_handle,
          this.$store.state.user_address,
          null,
          false
        );
        this.paying = false;
      };
      let lThis = this;
      let lConsole = console;
      this.paying = true;
      window.relayone.render(this.$refs["payButton"], {
        to: "fronks@relayx.io",
        amount: 0.33,
        currency: "BSV",
        opReturn: ["0x6d02", "Buying FRONKS NFT"],
        onPayment: (e) => {
          lConsole.log(e);
          lThis.open = true;
          confirmPaid(e);
        },
        onError: (err) => {
          alert(err);
          console.log(err);
        },
      });
    },
    closeModal() {
      if (window.location.toString().includes("localhost"))
        window.location = "http://localhost:8080/mint";
      else {
        window.location.reload();
      }
    },
  },
  computed: {
    mintText() {
      if (this.paidForMint) {
        return "VIEW";
      }
      if (this.paying) {
        return "PAYING";
      }
      return "BUY";
      //return "🐸 MINTING SOON 🐸";
    },
    orderCount() {
      if (this.allCounters[0]?.count) {
        return this.allCounters[0].count;
      }
      return 0;
    },
    mintButtonClasses() {
      if (!this.paidForMint) {
        if (this.paying) {
          return "disabled bg-gradient-to-r from-green-400 via-green-700 to-green-300 animate-pulse";
        }
        return "bg-gradient-to-r from-yellow-400 via-yellow-700 to-yellow-300"; // animate-pulse";
      }
      return "bg-gradient-to-r from-green-400 via-green-700 to-green-300 animate-pulse";
    },
    isLoggedIn() {
      return this.isLogin;
    },
  },
};
</script>

<style lang="scss" scoped></style>
