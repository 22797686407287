<template>
  <div
    class="h-56 bg-cover bg-center"
    style="
      background-image: url('https://slavettes-layers.s3.amazonaws.com/fronks-images/frogs-footer.png');
    "
  ></div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>
