<template>
  <menu-component></menu-component>
  <div
    class="h-56 bg-cover bg-center"
    style="
      background-image: url('https://slavettes-layers.s3.amazonaws.com/fronks-images/banner-image.png');
    "
  ></div>
  <div class="flex">
    <div class="w-full"></div>
    <div class="">
      <button
        @click="lastPage"
        class="p-2 m-2 text-white text-xl bg-gradient-to-r from-blue-500 to-indigo-500 rounded-xl"
      >
        Back
      </button>
    </div>
    <div class="">
      <button
        @click="nextPage"
        class="p-2 m-2 text-white text-xl bg-gradient-to-r from-blue-500 to-indigo-500 rounded-xl"
      >
        Next
      </button>
    </div>
    <div class="w-full"></div>
  </div>
  <div v-if="orderedNFTs?.length < 1" class="w-full">
    <img
      class="mx-auto max-w-xl"
      src="https://slavettes-layers.s3.amazonaws.com/fronks-images/404Frogs.png"
    />
  </div>
  <div class="grid grid-cols-1 lg:grid-cols-4">
    <div
      v-for="(order, index) in orderedNFTs"
      :key="index"
      class="col-span-1 mb-4 mx-1 bg-gray-900 rounded"
    >
      <img :src="getBerryUrl(order)" class="w-full" />
      <div class="grid grid-cols-4" :class="rankClass(order)">
        <div class="w-full col-span-2">
          <div class="mx-auto">Edition {{ order.props.no }}</div>
        </div>
        <div class="col-span-1">
          <div class="mx-auto">Rank # {{ rankNFT(order) }}</div>
        </div>
        <div class="col-span-1">
          <div class="mx-auto">₿ {{ order.satoshis / 100000000 }}</div>
        </div>
      </div>
      <div class="">
        <div class="text-4xl pt-2" :class="rankTextClass(order)">
          {{ rankText(order) }}
        </div>
      </div>
      <div class="m-4">
        <a
          noopener
          norel
          target="_blank"
          class="bg-blue-500 rounded-xl text-white p-2 m-2"
          :href="`https://www.relayx.com/market/2a14ca118a51920d99c370e4ba76ca5ecdc41ddbc5c5556cb9b6fd0ba2206a79_o2/${order.location}`"
        >
          BUY ON RELAY
        </a>
      </div>
    </div>
  </div>
  <div class="flex">
    <div class="w-full"></div>
    <div class="">
      <button
        @click="lastPage"
        class="p-2 m-2 text-white text-xl bg-gradient-to-r from-blue-500 to-indigo-500 rounded-xl"
      >
        Back
      </button>
    </div>
    <div class="">
      <button
        @click="nextPage"
        class="p-2 m-2 text-white text-xl bg-gradient-to-r from-blue-500 to-indigo-500 rounded-xl"
      >
        Next
      </button>
    </div>
    <div class="w-full"></div>
  </div>
  <footer-component> </footer-component>
</template>

<script>
import MenuComponent from "./../components/MenuComponent.vue";
import { reactive, toRefs } from "vue";
import axios from "axios";
import FooterComponent from "./../components/FooterComponent.vue";
let rank = [
  73, 573, 273, 867, 632, 986, 660, 465, 644, 409, 985, 486, 820, 504, 796, 643,
  342, 763, 358, 164, 615, 962, 130, 196, 833, 326, 234, 757, 379, 853, 697,
  499, 270, 675, 219, 953, 491, 411, 976, 727, 586, 765, 460, 553, 145, 239,
  724, 566, 51, 416, 493, 312, 313, 744, 797, 490, 705, 830, 636, 377, 415, 612,
  687, 911, 201, 603, 324, 310, 474, 34, 190, 431, 209, 674, 736, 144, 446, 61,
  572, 920, 69, 226, 137, 253, 49, 107, 559, 649, 556, 863, 25, 892, 554, 46,
  882, 218, 122, 159, 299, 15, 604, 631, 249, 146, 231, 970, 669, 207, 90, 133,
  99, 877, 236, 412, 813, 444, 544, 929, 517, 56, 155, 730, 340, 859, 440, 681,
  295, 899, 657, 360, 393, 551, 471, 668, 403, 50, 555, 489, 356, 319, 271, 184,
  401, 394, 569, 549, 245, 801, 115, 557, 208, 353, 136, 708, 658, 742, 131,
  211, 458, 741, 567, 224, 677, 968, 404, 387, 725, 84, 278, 826, 793, 832, 638,
  238, 179, 154, 368, 764, 799, 570, 419, 807, 913, 864, 870, 919, 823, 739, 8,
  938, 731, 608, 887, 988, 786, 561, 260, 62, 142, 654, 810, 704, 952, 449, 104,
  701, 328, 837, 537, 286, 522, 531, 380, 582, 939, 695, 148, 240, 97, 605, 710,
  188, 215, 468, 560, 908, 851, 309, 994, 760, 119, 424, 198, 7, 856, 686, 9,
  977, 181, 946, 129, 941, 524, 21, 102, 71, 775, 663, 114, 186, 755, 448, 194,
  75, 902, 475, 898, 580, 903, 242, 495, 442, 754, 40, 973, 220, 48, 296, 964,
  47, 33, 250, 174, 854, 282, 413, 85, 841, 320, 228, 975, 646, 421, 300, 515,
  165, 609, 94, 247, 726, 428, 32, 734, 751, 507, 839, 496, 622, 63, 29, 453,
  267, 961, 857, 189, 516, 774, 659, 13, 214, 506, 874, 135, 222, 427, 747, 803,
  18, 788, 191, 980, 221, 323, 746, 881, 385, 827, 80, 332, 364, 922, 65, 485,
  361, 563, 362, 844, 831, 800, 930, 811, 620, 501, 885, 338, 318, 371, 616,
  274, 437, 936, 350, 37, 596, 170, 389, 665, 216, 149, 252, 634, 163, 825, 910,
  662, 901, 311, 626, 301, 623, 303, 376, 472, 847, 509, 42, 923, 288, 748, 802,
  689, 503, 432, 534, 806, 55, 536, 641, 688, 981, 607, 264, 745, 935, 759, 590,
  297, 728, 95, 20, 987, 395, 591, 335, 470, 399, 482, 206, 846, 916, 284, 581,
  302, 836, 972, 375, 950, 420, 124, 738, 990, 535, 109, 991, 5, 770, 974, 861,
  258, 157, 96, 814, 494, 423, 713, 984, 996, 175, 132, 777, 576, 693, 388, 275,
  2, 60, 931, 661, 843, 461, 276, 785, 339, 325, 35, 402, 684, 166, 464, 850,
  886, 812, 743, 587, 781, 171, 978, 183, 692, 672, 317, 562, 520, 285, 979,
  965, 369, 372, 22, 508, 140, 315, 117, 110, 439, 414, 860, 57, 265, 349, 502,
  880, 971, 966, 454, 719, 287, 989, 642, 894, 343, 14, 212, 466, 545, 229, 940,
  400, 78, 187, 433, 173, 543, 41, 469, 280, 768, 233, 277, 425, 139, 648, 597,
  712, 914, 435, 72, 232, 153, 473, 679, 584, 266, 552, 261, 790, 611, 530, 771,
  16, 390, 685, 884, 281, 498, 574, 891, 951, 79, 351, 462, 4, 158, 772, 650,
  630, 76, 263, 667, 304, 995, 398, 341, 694, 784, 937, 126, 31, 383, 406, 789,
  637, 386, 673, 729, 28, 798, 134, 193, 89, 405, 106, 66, 10, 652, 613, 532,
  593, 791, 244, 378, 733, 928, 426, 890, 510, 761, 664, 306, 176, 246, 262,
  330, 160, 818, 223, 217, 548, 568, 408, 101, 456, 858, 195, 628, 204, 45, 290,
  123, 956, 251, 578, 752, 992, 12, 879, 779, 108, 865, 895, 783, 316, 714, 917,
  564, 268, 619, 737, 363, 497, 3, 538, 438, 269, 565, 703, 815, 656, 180, 795,
  255, 614, 357, 948, 635, 528, 445, 513, 723, 138, 478, 541, 740, 666, 143, 27,
  210, 150, 457, 782, 999, 185, 116, 927, 23, 852, 381, 105, 429, 152, 305, 365,
  521, 715, 70, 248, 967, 957, 720, 476, 199, 706, 391, 921, 205, 780, 829, 463,
  671, 711, 227, 93, 680, 627, 540, 451, 256, 162, 963, 257, 618, 905, 821, 484,
  949, 113, 862, 430, 518, 488, 38, 241, 819, 103, 500, 418, 292, 525, 121, 670,
  492, 732, 327, 505, 585, 422, 441, 735, 897, 112, 346, 230, 542, 74, 373, 639,
  889, 838, 479, 933, 579, 254, 526, 624, 828, 481, 447, 756, 11, 942, 147, 750,
  896, 547, 653, 600, 283, 918, 721, 945, 767, 868, 954, 550, 872, 52, 998, 944,
  625, 436, 279, 621, 983, 915, 805, 434, 81, 845, 617, 676, 722, 26, 893, 291,
  583, 58, 374, 778, 24, 716, 392, 883, 333, 766, 67, 450, 993, 98, 120, 213,
  88, 225, 958, 683, 243, 718, 869, 354, 906, 359, 370, 787, 533, 477, 417, 577,
  329, 943, 396, 794, 808, 53, 592, 167, 54, 202, 589, 83, 352, 834, 1, 523,
  182, 19, 610, 753, 348, 168, 87, 77, 678, 259, 322, 691, 696, 816, 237, 717,
  519, 947, 822, 629, 873, 308, 156, 336, 575, 924, 36, 842, 410, 598, 909, 960,
  44, 68, 776, 912, 125, 529, 588, 817, 907, 161, 151, 655, 111, 6, 934, 294,
  702, 762, 443, 698, 699, 707, 337, 700, 773, 809, 384, 487, 82, 601, 926, 334,
  900, 645, 172, 118, 871, 30, 293, 647, 192, 86, 959, 141, 749, 459, 982, 345,
  407, 709, 178, 848, 546, 527, 307, 932, 969, 804, 888, 43, 91, 17, 651, 355,
  925, 177, 571, 367, 606, 690, 321, 366, 511, 824, 792, 594, 298, 849, 840,
  382, 633, 169, 128, 483, 904, 878, 455, 59, 997, 39, 955, 855, 875, 835, 100,
  92, 876, 595, 682, 64, 602, 200, 197, 331, 769, 866, 512, 289, 452, 127, 480,
  558, 314, 758, 235, 272, 640, 347, 599, 344, 467, 514, 539, 203, 397,
];
export default {
  components: { MenuComponent, FooterComponent },
  async setup() {
    let { data } = await axios.get(
      "https://staging-backend.relayx.com/api/market/2a14ca118a51920d99c370e4ba76ca5ecdc41ddbc5c5556cb9b6fd0ba2206a79_o2/orders"
    );
    let orders_raw = data.data.orders;
    let orders = orders_raw.filter((o) => rank.indexOf(o.props.no) < 1499);
    orders.sort((a, b) => {
      let a1 = parseInt(a.satoshis, 10);
      let b1 = parseInt(b.satoshis, 10);
      return a1 - b1;
    });
    const state = reactive({
      count: 0,
      page: 0,
    });

    return {
      ...toRefs(state),
      orders,
    };
  },
  methods: {
    getBerryUrl(order) {
      let suffix = order.berry.txid;
      if (suffix) {
        return "https://berry.relayx.com/" + suffix;
      }
      return "";
    },
    rankNFT(nft) {
      const i = parseInt(nft.props.no);
      let rankSpot = rank.indexOf(i);
      console.log(rankSpot);
      return rankSpot + 1;
    },
    rankClass(nft) {
      let currentRank = this.rankNFT(nft);
      if (currentRank <= 9) {
        return "bg-teal-200 text-teal-800";
      }
      if (currentRank <= 49) {
        return "bg-yellow-800 text-yellow-200";
      }
      if (currentRank <= 149) {
        return "bg-red-600 text-red-200";
      }
      if (currentRank <= 299) {
        return "bg-green-600 text-green-100";
      }
      if (currentRank <= 499) {
        return "bg-blue-600 text-blue-200";
      }
      return "bg-gray-700 text-gray-100";
    },
    rankTextClass(nft) {
      let currentRank = this.rankNFT(nft);
      if (currentRank <= 9) {
        return "font-extrabold bg-gradient-to-b from-teal-200 via-indigo-400 to-blue-700 bg-clip-text text-transparent";
      }
      if (currentRank <= 49) {
        return "font-extrabold bg-gradient-to-b from-yellow-200 via-yellow-400 to-yellow-700 bg-clip-text text-transparent";
      }
      if (currentRank <= 149) {
        return "font-extrabold bg-gradient-to-b from-red-200 via-red-400 to-red-700 bg-clip-text text-transparent";
      }
      if (currentRank <= 299) {
        return "font-extrabold bg-gradient-to-b from-green-200 via-green-400 to-green-700 bg-clip-text text-transparent";
      }
      if (currentRank <= 499) {
        return "font-extrabold bg-gradient-to-b from-blue-200 via-blue-400 to-blue-700 bg-clip-text text-transparent";
      }
      return "font-extrabold bg-gradient-to-b from-gray-200 via-gray-400 to-gray-700 bg-clip-text text-transparent";
    },
    rankText(nft) {
      let currentRank = this.rankNFT(nft);
      if (currentRank <= 9) {
        return "Exotic";
      }
      if (currentRank <= 49) {
        return "Legendary";
      } else if (currentRank <= 149) {
        return "Epic";
      } else if (currentRank <= 299) {
        return "Rare";
      } else if (currentRank <= 499) {
        return "Uncommon";
      }
      return "Common";
    },
    resetPage() {
      this.page = 0;
    },
    nextPage() {
      this.page = this.page + 1;
      console.log(this.page);
    },
    lastPage() {
      if (this.page >= 2) {
        this.page = this.page - 1;
      }
    },
  },
  computed: {
    orderedNFTs() {
      let pageStart = 0;
      let pageEnd = 20;
      if (this.page >= 1) {
        pageStart = this.page * 20;
        pageEnd = pageStart + 20;
      }
      return this.orders.slice(pageStart, pageEnd);
    },
  },
};
</script>

<style lang="scss" scoped></style>
