<template>
  <MenuComponent />
  <div class="home text-white">
    <div
      class="h-56 bg-cover bg-center"
      style="
        background-image: url('https://slavettes-layers.s3.amazonaws.com/fronks-images/banner-image.png');
      "
    ></div>
    <div class="w-full">
      <div class="pt-16 m-2 bg-gray-900 rounded-xl">
        <div
          class="text-4xl md:text-7xl font-extrabold bg-gradient-to-b from-green-200 via-green-400 to-green-700 bg-clip-text text-transparent"
        >
          The swamp party has started!<br />╭⊚‿⊚╮
        </div>
        <div class="pt-6 text-xl font-bold text-gray-300 lg:text-2xl px-3">
          With a supply of only 999 editions, there are only so many who can
          play a role in the story of Fronks alongside our team
        </div>
      </div>
      <div class="w-full">
        <img
          class="w-full"
          alt=""
          src="https://slavettes-layers.s3.amazonaws.com/fronks-images/mint.png"
        />
        <img
          class="w-full -pt-56"
          alt=""
          src="https://slavettes-layers.s3.amazonaws.com/fronks-images/fronks-green-bg.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MenuComponent from "./../components/MenuComponent.vue";

export default {
  components: {
    MenuComponent,
  },
  name: "HomeView",
  mounted() {
    // window.relayone.render(this.$refs["payButton"], {
    //   to: "zackwins@relayx.io",
    //   amount: 0.01,
    //   currency: "USD",
    //   onPayment: (e) => {
    //     alert("paid:", e);
    //   },
    //   onError: (err) => {
    //     alert(err);
    //     console.log(err);
    //   },
    // });
  },
  methods: {
    async showRelayButton() {
      //let lthis = this;
      window.relayone.render(this.$refs["payButton"], {
        to: "zackwins@relayx.io",
        amount: 0.01,
        currency: "USD",
        opReturn: ["0x6d02", "Buying FRONKS NFT"],
        onPayment: (e) => {
          alert("paid:", e.txid);
          //lthis.$router.push("/about");
        },
        onError: (err) => {
          alert(err);
          console.log(err);
        },
      });
    },
  },
};
</script>
