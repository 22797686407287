import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  key: "Fronks",
  storage: window.localStorage,
});
//FRONKS SEED: very own fiber grocery hundred festival rally leader finish deny vault raw
export default createStore({
  state: {
    relayx_handle: "",
    user_address: "",
    user_jigs: [],
    paidForMint: false,
  },
  getters: {},
  mutations: {
    setRelayxHandle(state, _handle) {
      state.relayx_handle = _handle;
    },
    setUserAddress(state, _userAddress) {
      state.user_address = _userAddress;
    },
    setUserJigs(state, _userJigs) {
      state.user_jigs = _userJigs;
    },
    setPaidForMint(state, paid) {
      state.paidForMint = paid;
    },
  },
  actions: {
    login({ commit }, { _handle, _userAddress }) {
      commit("setRelayxHandle", _handle);
      commit("setUserAddress", _userAddress);
    },
    logout({ commit }) {
      commit("setRelayxHandle", "");
      commit("setUserAddress", "");
      commit("setUserJigs", []);
    },
  },
  plugins: [vuexLocal.plugin],
  modules: {},
});
